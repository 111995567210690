import React from 'react';
import AppPage from './AppPage';

const Sectors = () => (
  <AppPage endpoint="/articles" title="Career Advice" />
)
export default Sectors;



