import React from 'react';
import AppPage from './AppPage';

const Sectors = () => (
  <AppPage endpoint="sectors" title="Sectors & Roles" />
)
export default Sectors;



