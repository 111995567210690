import React from 'react';
import AppPage from './AppPage';

const Home = () => (
  <AppPage endpoint="home" />
)
export default Home;



