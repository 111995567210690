import React from 'react';
import AppPage from './AppPage';

const Explore = () => (
  <AppPage endpoint="explore" title="Explore the Framework" />
)
export default Explore;



