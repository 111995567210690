import React from 'react';

const KitchenSink = () => (
  <div className="kitchensink-wrapper">
    <div className="row">
      <div className="col-sm-12">
        <h1>Hi</h1>
      </div>
    </div>
  </div>
);

export default KitchenSink;