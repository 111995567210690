import React from 'react';
import AppPage from './AppPage';

const CareerQuizIntro = () => (
  <AppPage endpoint="careerQuizIntro" title="Career Quiz" />
)
export default CareerQuizIntro;



