var conf;

if (process.env.NODE_ENV == 'production'){
  conf = {
    apiUrl: 'http://mynursingfuture.com.au/',
    apiBaseUrl: 'app/api/',
    siteUrl: 'http://mynursingfuture.com.au/',
    shareBaseUrl: 'http://180.235.131.104/',
    imagesDirectory: 'app/Content/img/'
  };
} else {
  // conf = {
  //     apiUrl: 'http://13.73.105.110',
  //   apiBaseUrl: '/api/',
  //   siteUrl: 'http://13.73.105.110',
  //   shareBaseUrl: 'http://13.73.105.110',
  //   imagesDirectory: '/Content/img/'
  // };
  conf = {
    // URL for API application
    apiUrl: 'http://localhost:50565/',
    // API base path, relative to the API Application
    apiBaseUrl: 'api/',
    // Site url with the content files
    siteUrl: 'http://localhost:50418/',
    // Cannot figure our what this shareBaseUrl is for, could be a functionality which is incomplete
    shareBaseUrl: 'http://localhost:3000/',
    // Content image directory
    imagesDirectory: 'app/Content/img/'
  };
}

if (window.conf !== undefined) {
  conf = {
    ...conf,
    ...window.conf,
  };
}

export default conf;