import React from 'react';
import AppPage from './AppPage';

const Why = () => (
  <AppPage endpoint="whyPrimaryHealthCare" title="Why Primary Health?" />
)
export default Why;



