import React from 'react';
import AppPage from './AppPage';

const Section = (props) => (
  <AppPage match={props.match} />
)
export default Section;



