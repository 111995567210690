import React from 'react';
import AppPage from './AppPage';

const SelfAssessmentIntro = () => (
  <AppPage endpoint="selfAssessmentIntro" title="Self Assessment" />
)
export default SelfAssessmentIntro;



